import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import {
  IconButton,
  Row,
  Col,
  Heading,
  Loader,
} from '@d-lighted/design-system';
import moment from 'moment';
import humps from 'lodash-humps';

import { isMobileDevice } from 'utils/mobileUtils';
import useSorting from 'containers/Sorting/hooks/useSorting';
import { timeRangeFormat } from 'utils/momentUtils';
import * as commonUtil from 'utils/commonUtils';
import {
  grayShade,
  primaryLightGreen,
  white,
  blueShade,
} from 'constants/colors';
import { getCurrentUser } from 'utils/userUtils';
import SortingDialog from 'components/SortingDialog';
import { useCalendarResource } from 'containers/Resource/hooks/useCalendarResource';
import { CalendarWrapper } from './components/CalendarWrapper';
import CustomView from './components/CustomView';
import EventContent from './components/EventContent';
import DayHeader from './components/DayHeader';
import EventCreatePopover from './components/EventCreatePopover';
import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  padding: 60px 30px 0;
`;

const ChangeSearchCriteria = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${blueShade};
  margin-left: 15px;

  &:hover {
    text-decoration: none;
  }
`;

const ContainerRow = styled(Row)`
  width: calc(100vw - 40px);
  overflow: auto;
  scroll-behavior: smooth;
`;

const Sorting = ({ intl, useNotify }) => {
  const currentUser = getCurrentUser();
  const camelizedUser = humps(currentUser);
  const { timeConvention } = camelizedUser?.company;
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [max, setMax] = useState(new Date());
  const [min, setMin] = useState(new Date());
  const timeFormat = commonUtil.getTimeFormat(timeConvention);
  const notify = useNotify(intl);
  const history = useHistory();
  const [query, setQuery] = useState(
    new URLSearchParams(window.location.search),
  );
  const [queryValues, setQueryValues] = useState('');
  const [openCreatePopover, setOpenCreatePopover] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const { slots, loading, searchTimeSlots } = useSorting(notify);
  const { postCalendarEvent } = useCalendarResource();
  const [postLoading, setPostLoading] = useState(false);
  const [creationStatus, setCreationStatus] = useState(null);

  const formats = {
    timeGutterFormat: date => moment(date).locale('en').format(timeFormat),
    selectRangeFormat: range => timeRangeFormat(range, timeFormat, 'en'),
    eventTimeRangeFormat: () => '',
    eventTimeRangeStartFormat: () => '',
    eventTimeRangeEndFormat: () => '',
  };

  function urlChangeHandler() {
    setQuery(new URLSearchParams(window.location.search));
  }

  function isInBetween(currentDate, minimum, maximum) {
    if (currentDate[0] > minimum[0] && currentDate[0] < maximum[0]) {
      return true;
    }
    if (
      currentDate[0] === minimum[0] &&
      currentDate[1] >= minimum[1] &&
      currentDate[0] < maximum[0]
    ) {
      return true;
    }
    if (
      currentDate[0] === maximum[0] &&
      currentDate[1] < maximum[1] &&
      currentDate[0] > minimum[0]
    ) {
      return true;
    }

    return (
      currentDate[0] === minimum[0] &&
      currentDate[1] >= minimum[1] &&
      currentDate[0] === maximum[0] &&
      currentDate[1] < maximum[1]
    );
  }

  const slotPropGetter = useCallback(
    date => {
      const dateSplit = [date.getHours(), date.getMinutes()];
      const minSplit = [min.getHours(), Math.round(min.getMinutes() / 15) * 15]; // This is to avoid having half slots outside the selection
      const maxSplit = [max.getHours(), max.getMinutes()];

      return {
        className: 'outside-selection',
        ...(!isInBetween(dateSplit, minSplit, maxSplit) && {
          style: {
            backgroundColor: grayShade,
          },
        }),
      };
    },
    [min, max],
  );

  const eventPropGetter = event => {
    return {
      className: moment(event.start).unix(),
      style: {
        border: `1px solid ${primaryLightGreen}`,
        color: primaryLightGreen,
        backgroundColor: white,
        fontSize: '12px',
        padding: '8px 8px',
      },
    };
  };

  const handleSelectEvent = event => {
    setPostLoading(false);
    setCreationStatus(null);
    setSelectedEvent(event);
    setOpenCreatePopover(true);
  };

  const handleAppointmentConfirmation = ({ title, event, meetingroom }) => {
    setPostLoading(true);
    postCalendarEvent(
      {
        start: event.start,
        end: event.end,
        name: title,
      },
      meetingroom.value,
      result => {
        setCreationStatus(result);
        setPostLoading(false);
      },
    );
  };

  const handleComplete = () => {
    history.push('/');
  };

  useEffect(() => {
    const unlisten = history.listen(urlChangeHandler);

    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query == null) return;

    const queryValuesObj = Array.from(query.entries()).reduce(
      (acc, [key, value]) => {
        const splitValue = value.split(',');
        acc[key] = splitValue.length > 1 ? splitValue : splitValue[0];
        return acc;
      },
      {},
    );
    setQueryValues(queryValuesObj);
    searchTimeSlots(queryValuesObj);
    setDefaultDate(moment(queryValuesObj.desiredDate[0]).toDate());

    const minSplit = queryValuesObj.startTime.split(':');
    setMin(new Date(0, 0, 0, minSplit[0], minSplit[1], 0));

    const maxSplit = queryValuesObj.endTime.split(':');
    setMax(new Date(0, 0, 0, maxSplit[0], maxSplit[1], 0));

    const startDate = moment(queryValuesObj.desiredDate[0]);
    const endDate = moment(queryValuesObj.desiredDate[1]);

    setLocalizer(l => {
      l.weekRange = Math.abs(startDate.diff(endDate, 'days'));
      return l;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!loading && slots && slots.length) {
      setTimeout(() => {
        const event = document.querySelector('.rbc-event');
        const content = document.querySelector('.scrolling-container');
        content.scrollTop = event.offsetTop - 30;
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (isMobileDevice()) {
    // TODO: Decide what to do in this situation
    return;
  }

  // eslint-disable-next-line consistent-return
  return (
    <Wrapper>
      <Row>
        <Col xs mt="8px">
          <IconButton
            mr="15px"
            style={{ top: '2px' }}
            onClick={() => history.push('/')}
          >
            <i className="fa fa-angle-left" aria-hidden="true" />
          </IconButton>
          <Heading size={2} style={{ display: 'inline-block' }}>
            <FormattedMessage {...messages.results} />
          </Heading>
        </Col>
      </Row>
      {loading ? (
        <Row width="100%" mt="30px">
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Loader />
          </Col>
        </Row>
      ) : (
        <>
          <Row mt="16px" mb="16px">
            <Col xs style={{ fontSize: '16px', textWrap: 'nowrap' }}>
              <span>
                <FormattedMessage
                  {...messages.duration}
                  values={{ duration: queryValues?.meetingTime }}
                />
              </span>
              {', '}
              <span>
                {moment(queryValues?.desiredDate?.[0]).format('L')}
                {' - '}
                {moment(queryValues?.desiredDate?.[1]).format('L')}
              </span>
              {', '}
              <span>
                <FormattedMessage
                  {...messages[
                    queryValues?.holidays === 'true'
                      ? 'includingHolidays'
                      : 'notIncludingHolidays'
                  ]}
                />
              </span>
              {', '}
              <span>
                {queryValues?.startTime}
                {' - '}
                {queryValues?.endTime}
              </span>

              <SortingDialog
                anchor={
                  <ChangeSearchCriteria>
                    <FormattedMessage {...messages.changeConditions} />
                  </ChangeSearchCriteria>
                }
                query={{
                  ...queryValues,
                  holidays: queryValues?.holidays === 'true',
                }}
              />
            </Col>
          </Row>
          {slots.length === 0 ? (
            <Row width="100%" mt="30px">
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  fontSize: '18px',
                }}
              >
                <FormattedMessage {...messages.noResult} />
              </Col>
            </Row>
          ) : (
            <ContainerRow className="scrolling-container">
              <Col>
                <CalendarWrapper>
                  <Calendar
                    defaultDate={defaultDate}
                    defaultView={Views.WEEK}
                    events={slots}
                    localizer={localizer}
                    slotPropGetter={slotPropGetter}
                    components={{
                      toolbar: () => null,
                      // eslint-disable-next-line react/display-name
                      event: props => <EventContent {...props} />,
                      week: {
                        // eslint-disable-next-line react/display-name
                        header: props => <DayHeader {...props} />,
                      },
                    }}
                    views={{
                      week: CustomView,
                    }}
                    formats={formats}
                    eventPropGetter={eventPropGetter}
                    step={15}
                    drilldownView={null}
                    scrollToTime={new Date()}
                    onSelectEvent={handleSelectEvent}
                    tooltipAccessor={null}
                  />
                </CalendarWrapper>
              </Col>
            </ContainerRow>
          )}
        </>
      )}

      <EventCreatePopover
        open={openCreatePopover}
        event={selectedEvent}
        onCancel={() => setOpenCreatePopover(false)}
        onConfirm={handleAppointmentConfirmation}
        onComplete={handleComplete}
        loading={postLoading}
        status={creationStatus}
      />
    </Wrapper>
  );
};

Sorting.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  useNotify: PropTypes.func.isRequired,
};

Sorting.defaultProps = {};

export default injectIntl(Sorting);
