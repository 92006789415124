import { defineMessages } from 'react-intl';

export default defineMessages({
  vacancy: {
    id: 'bigCalendar.calendarHeader.vacancy',
    defaultMessage: 'Vacant',
  },
  inUse: {
    id: 'bigCalendar.calendarHeader.inUse',
    defaultMessage: 'In Use',
  },
  checkedOut: {
    id: 'bigCalendar.calendarHeader.checkedOut',
    defaultMessage: 'Checked out',
  },
  inAMeeting: {
    id: 'bigCalendar.calendarHeader.inAMeeting',
    defaultMessage: 'In a Meeting',
  },
  reserved: {
    id: 'bigCalendar.calendarHeader.reserved',
    defaultMessage: 'Reserved',
  },
  buttonText: {
    id: 'bigCalendar.calendarHeader.dropdown.buttonText',
    defaultMessage: 'Conference room that can be used from now on',
  },
  notSpecified: {
    id: 'bigCalendar.calendarHeader.dropdown.notSpecified',
    defaultMessage: 'Not Specified',
  },
  fifteenMins: {
    id: 'bigCalendar.calendarHeader.dropdown.fifteenMinutes',
    defaultMessage: '15 minutes from now',
  },
  thirtyMins: {
    id: 'bigCalendar.calendarHeader.dropdown.thirtyMinutes',
    defaultMessage: '30 minutes from now',
  },
  sixtyMins: {
    id: 'bigCalendar.calendarHeader.dropdown.sixtyMinutes',
    defaultMessage: '60 minutes from now',
  },
  successDialogTitle: {
    id: 'bookingSuccessDialog.title',
    defaultMessage: 'Reservation Made',
  },
  succMsg: {
    id: 'bookingSuccessDialog.successMsg',
    defaultMessage:
      'The meeting room reservation has been reflected in your scheduler',
  },
  succMsgShort: {
    id: 'bookingSuccessDialog.successMsgShort',
    defaultMessage:
      'The meeting room reservation has been reflected in your scheduler',
  },
  complete: {
    id: 'bookingSuccessDialog.button.completion',
    defaultMessage: 'Complete',
  },
  returnRefined: {
    id: 'bookingSuccessDialog.button.returnToRefined',
    defaultMessage: 'Return to refined result',
  },
  errorDialogTitle: {
    id: 'bookingErrorDialog.title',
    defaultMessage: 'Couldnt Reserve',
  },
  errMsg: {
    id: 'notify.home.postEvent.errorMsg',
    defaultMessage: 'Error while creating event',
  },
  return: {
    id: 'bookingResultDialog.button',
    defaultMessage: 'Return',
  },
  titleLabel: {
    id: 'eventCreateDialog.title.label',
    defaultMessage: 'Title',
  },
  noteLabel: {
    id: 'eventCreateDialog.note.label',
    defaultMessage: 'Notes',
  },
  reserveButton: {
    id: 'reserve.button',
    defaultMessage: 'Reserve',
  },
  privateEvent: {
    id: 'resourceCalendarEvent.privateEvent',
    defaultMessage: 'Private event',
  },
  privateDescription: {
    id: 'resourceCalendarEvent.privateEvent.description',
    defaultMessage:
      'Sorry, the detail can not be seen due to the private event.',
  },
  meeting: {
    id: 'resourceCalendarEvent.meeting',
    defaultMessage: '打ち合わせ',
  },
  overlap: {
    id: 'bigCalendar.calendarBody.selectionOverlaps',
    defaultMessage:
      'The time you specified has already been reserved for another appointment.',
  },
  reserveStatus: {
    id: 'bigCalendar.heading.reservationStatus',
    defaultMessage: 'Reservation Status',
  },
  confirmDelete: {
    id: 'eventDetailsDialog.delete.confirmation',
    defaultMessage: 'Do you really want to delete this appointment?',
  },
  deleteEventSuccessMsg: {
    id: 'notify.home.deleteEvent.successMsg',
    defaultMessage: 'Succeeded in deleting the event.',
  },
});
