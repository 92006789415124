import React from 'react';
import {
  Row,
  Col,
  InputSelect,
  Loader,
  IconNew as Icon,
} from '@d-lighted/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { useHistory } from 'react-router';

import Popover from 'components/Popover';
import { darkCyan, deepGreen, grayThin2 } from 'constants/colors';
import { composeValidators, useValidation } from 'hooks/useValidation';
import {
  ConfirmationDialogButton,
  ConfirmationReturnButton,
  CustomForm,
  DialogHeader,
  InputField,
  InputLabel,
  Wrapper,
} from './styled';
import messages from '../messages';

function EventCreatePopover({
  open,
  event,
  onCancel,
  onConfirm,
  onComplete,
  loading,
  status,
}) {
  const intl = useIntl();
  const history = useHistory();
  const { titleRequired, inputLength255 } = useValidation();

  if (event == null) return null;

  const meetingrooms = event?.resources
    .map(resource => {
      return {
        value: resource.uid,
        label: resource.name,
      };
    })
    .sort((a, b) =>
      a?.label?.localeCompare(b.label, undefined, { sensitivity: 'base' }),
    );

  const generateResourceEventId = () => {
    return `rbc-event ${moment(event?.start).unix()}`;
  };

  const Content = () => {
    if (loading) {
      return (
        <Wrapper>
          <Loader />
        </Wrapper>
      );
    }
    if (status === 'success') {
      return (
        <Wrapper>
          <Row mb="30px" width="100%">
            <Col xs display="flex" alignItems="center">
              <DialogHeader>
                <Icon
                  className="material-icons"
                  color={deepGreen}
                  fontSize="28px"
                  style={{
                    position: 'relative',
                    bottom: '-6px',
                    marginRight: '10px',
                  }}
                >
                  check_circle
                </Icon>
                <FormattedMessage {...messages.success} />
              </DialogHeader>
            </Col>
          </Row>
          <Row width="100%" alignItems="flex-start" mb="20px">
            <Col color={grayThin2}>
              <p>
                {`${moment(event.start).format('LL HH:mm')} - ${moment(
                  event.end,
                ).format('HH:mm')}`}
              </p>
              <FormattedMessage {...messages.successMsg} />
            </Col>
          </Row>
          <Row width="100%" justifyContent="flex-end">
            <Col xs />
            <Col>
              <ConfirmationReturnButton onClick={() => history.go(0)}>
                <FormattedMessage {...messages.returnToFilter} />
              </ConfirmationReturnButton>
            </Col>
            <Col>
              <ConfirmationDialogButton onClick={onComplete}>
                <FormattedMessage {...messages.complete} />
              </ConfirmationDialogButton>
            </Col>
          </Row>
        </Wrapper>
      );
    }
    return (
      <Form
        onSubmit={onConfirm}
        initialValues={{
          title: intl.formatMessage(messages.meeting),
          meetingroom: meetingrooms[0],
          event,
        }}
        render={({ handleSubmit }) => (
          <CustomForm onSubmit={handleSubmit}>
            <Row mb="30px" width="100%">
              <Col xs display="flex" alignItems="center">
                <DialogHeader>
                  {`${moment(event.start).format('LL HH:mm')} - ${moment(
                    event.end,
                  ).format('HH:mm')}`}
                </DialogHeader>
              </Col>
            </Row>
            <Row mb="30px" width="100%" style={{ flexDirection: 'column' }}>
              <Col xs>
                <InputLabel>
                  <FormattedMessage {...messages.roomLabel} />
                </InputLabel>
              </Col>
              <Col xs>
                <Field
                  id="meetingroom"
                  name="meetingroom"
                  component={InputSelect}
                  options={meetingrooms}
                  optionColor={darkCyan}
                />
              </Col>
            </Row>
            <Row mb="30px" width="100%" style={{ flexDirection: 'column' }}>
              <Col xs>
                <InputLabel>
                  <FormattedMessage {...messages.titleLabel} />
                </InputLabel>
              </Col>
              <Col xs>
                <Field
                  id="title"
                  name="title"
                  component={InputField}
                  placeholder=""
                  validate={composeValidators(titleRequired, inputLength255)}
                />
              </Col>
            </Row>
            <Row width="100%" justifyContent="flex-end">
              <Col xs />
              <Col>
                <ConfirmationDialogButton
                  className="cancel_button"
                  type="button"
                  onClick={onCancel}
                >
                  <FormattedMessage {...messages.cancel} />
                </ConfirmationDialogButton>
              </Col>
              <Col>
                <ConfirmationDialogButton
                  className="confirm_button"
                  type="submit"
                >
                  <FormattedMessage {...messages.reserve} />
                </ConfirmationDialogButton>
              </Col>
            </Row>
          </CustomForm>
        )}
      />
    );
  };

  return (
    <>
      <Popover
        open={open}
        positionTargetKey="className"
        positionTargetValue={generateResourceEventId()}
        onClickOverlay={() => {
          if (loading) return;

          if (status === 'success') {
            onComplete();
          } else {
            onCancel();
          }
        }}
        overlay={false}
      >
        <Content />
      </Popover>
    </>
  );
}

EventCreatePopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  event: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default EventCreatePopover;
