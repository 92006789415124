import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import samlSignInRoute from 'screens/SamlSignIn/route';
import homeRoute from './screens/Home/route';
import signInRoute from './screens/SignIn/route';
import resourceRoute from './screens/Resource/route';
import reserveRoute from './screens/Reserve/route';
import webViewRoute from './screens/WebView/route';
import sortingRoute from './screens/Sorting/route';

const combineRoutes = [
  ...homeRoute,
  ...signInRoute,
  ...resourceRoute,
  ...reserveRoute,
  ...samlSignInRoute,
  ...webViewRoute,
  ...sortingRoute,
];

const publicRoute = (route, props) => (
  <Route
    key={route.path}
    path={route.path}
    exact={route.exact}
    render={restProps => {
      return <route.component {...restProps} {...props} />;
    }}
  />
);

const privateRoute = (route, authenticated, currentUser, subdomain, props) => (
  <Route
    key={route.path}
    path={route.path}
    exact={route.exact}
    render={restProps => {
      // if (
      //   currentUser != null &&
      //   authenticated &&
      //   window.location.pathname !== '/sign_in' &&
      //   window.location.host !==
      //     `${subdomain}${process.env.REACT_APP_ROOMS_WEB_PATH}`
      // ) {
      //   localStorage.clear();
      //   window.location.href = `${window.location.protocol}//${subdomain}${process.env.REACT_APP_ROOMS_WEB_PATH}/sign_in`;
      // }

      if (!authenticated) {
        if (window.location.pathname === '/') {
          const path = window.location.href;
          if (path.includes('?resource_uid='))
            return (
              <Redirect
                to={{
                  pathname: '/sign_in',
                  state: {
                    path,
                  },
                }}
              />
            );
        }
        return (
          <Redirect
            to={{
              pathname: '/sign_in',
            }}
          />
        );
      }

      if (route.validation == null || route.validation())
        return <route.component {...restProps} {...props} />;

      return <Redirect to={{ pathname: '/' }} />;
    }}
  />
);

export const routeGenerator = ({
  authenticated,
  currentUser,
  subdomain,
  ...props
}) => {
  return combineRoutes.map(route =>
    route.type === 'public'
      ? publicRoute(route, props)
      : privateRoute(route, authenticated, currentUser, subdomain, props),
  );
};
