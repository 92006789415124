import { hideLoading, showLoading } from 'react-redux-loading-bar';
import moment from 'moment';
import axios from 'setupAxios';
import messages from 'containers/Home/messages';
import {
  getEvents,
  getVisibleResources,
  setCalendarEvent,
  setFilteredEvents,
  setFilteredResources,
  setFilteringTime,
  setLoading,
  setLoadingMore,
  setMeeting,
  setNewCalendarEvent,
  setSelectedDate,
  setTemporaryEvent,
  setAvailableTimeSlots,
  setCurrentPage,
  setTotalPages,
  setAvailableInstantMeetingCreate,
} from 'containers/Home/actions';
import { isEmpty, uniqBy } from 'lodash';
import humps from 'lodash-humps';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as dateUnitTypes from 'constants/dateUnitTypes';
import { uniqueId } from 'utils/commonUtils';
import { timeRangeFormat } from 'utils/momentUtils';
import { downloadFile } from 'utils/downloadUtils';
import { isMobileDevice } from 'utils/mobileUtils';
import {
  broadcastCheckIn,
  broadcastCheckOut,
  broadcastInstaMeeting,
} from 'utils/webViewUtils';

function buildResourcesAndEvents(data) {
  return data.resourceCalendarEventLists.reduce(
    (acc, resourceEvent) => {
      const { resource } = resourceEvent;

      if (!resource?.providerType) return acc;

      switch (resource.providerType) {
        case 'google':
          // eslint-disable-next-line no-case-declarations
          const {
            name: googleName,
            capacity: googleCapacity,
            floorName,
            buildingId,
          } = resource.resourceGoogle;

          acc[0].push({
            resourceId: resource.uid,
            resourceTitle: googleName,
            capacity: googleCapacity || 0,
            floorName,
            buildingId,
            providerType: resource.providerType,
            visible: true,
          });
          break;
        case 'microsoft':
          // eslint-disable-next-line no-case-declarations
          const {
            name: msName,
            capacity: msCapacity,
            floorNumber,
            buildingName,
          } = resource.resourceMicrosoft;

          acc[0].push({
            resourceId: resource.uid,
            resourceTitle: msName,
            capacity: msCapacity || 0,
            floorName: floorNumber,
            buildingName,
            providerType: resource.providerType,
            visible: true,
          });
          break;
        case 'garoon':
          // eslint-disable-next-line no-case-declarations
          const {
            name: garoonName,
            facilityGroupName,
          } = resource.resourceGaroon;

          acc[0].push({
            resourceId: resource.uid,
            resourceTitle: garoonName,
            buildingId: facilityGroupName,
            providerType: resource.providerType,
            visible: true,
          });
          break;
        default:
      }

      acc[1] = acc[1].concat(
        resourceEvent.events.map(event => {
          return {
            ...event,
            ...{
              resourceId: resourceEvent.resource.uid,
              start: new Date(event.start),
              end: new Date(event.end),
            },
          };
        }),
      );
      // eslint-disable-next-line consistent-return
      return acc;
    },
    [[], []],
  );
}

const buildUpdatedEvents = (events, meeting, resourceId) => {
  // NOTE: Garoonの繰り返しイベントの場合、meetingのevent_uidが{id}/{repeatId}となっているため
  const eventIdWithRepeatId = event => {
    if (!event.repeatId) return event.id;
    return `${event.id}/${event.repeatId}`;
  };
  if (events.some(event => eventIdWithRepeatId(event) === meeting.eventUid)) {
    return events.map(event => {
      if (eventIdWithRepeatId(event) === meeting.eventUid) {
        let checkedInEvent = {
          ...event,
          checkedInAt: meeting.checkedInAt,
          checkedOutAt: meeting.checkedOutAt,
          meetingUid: meeting.uid,
        };

        if (meeting.checkedInAt !== null && meeting.checkedOutAt === null) {
          checkedInEvent = {
            ...checkedInEvent,
            checkInAble: false,
            checkOutAble: true,
          };
        }

        if (meeting.checkedInAt !== null && meeting.checkedOutAt !== null) {
          checkedInEvent = {
            ...checkedInEvent,
            checkInAble: false,
            checkOutAble: false,
          };
        }

        return checkedInEvent;
      }
      return event;
    });
  }
  return [
    ...events,
    {
      ...meeting,
      id: meeting.eventUid,
      resourceId,
    },
  ];
};

const getResourceAndEvent = (notify, selectedBuilding, selectedDate, page) => {
  return async dispatch => {
    dispatch(showLoading());

    try {
      let query = '';

      if (selectedBuilding.building_id != null) {
        query =
          selectedBuilding.provider_type === 'google'
            ? `?building_id=${selectedBuilding.building_id}`
            : `?facility_group_id=${selectedBuilding.building_id}`;
      }

      if (selectedBuilding.room_list_address != null)
        query = `?room_list_address=${selectedBuilding.room_list_address}`;

      if (selectedDate) {
        const currentDate = moment().format('YYYY-MM-DD');
        if (moment(selectedDate).format('YYYY-MM-DD') !== currentDate) {
          query += query ? '&' : '?';
          query += `date=${moment(selectedDate).format('YYYY-MM-DD')}`;
        }
      }

      if (page) {
        query += query ? '&' : '?';
        query += `page=${page}`;
      }

      const localEditConfig = JSON.parse(
        localStorage.getItem('roomsWebEditConfig'),
      );

      const params = {};
      const invisibleResources = localEditConfig?.invisibleResources;
      if (invisibleResources?.length > 0) {
        const invisibleResourceIds = invisibleResources.map(
          resource => resource.resourceId,
        );
        params.exclude_resource_uids = invisibleResourceIds;
      }

      const { data } = await axios(
        `/company/resource_calendar_event_lists${query}`,
        {
          params,
        },
      ).catch(e => e.response);

      if (!data || (data && data?.error)) {
        dispatch(hideLoading());
        notify.setError(
          messages.notifyAlert,
          data?.error?.message || messages.getResEvtErrSubMsg,
        );
      } else if (!isEmpty(data)) {
        dispatch(hideLoading());
        if (!isEmpty(data?.resource_calendar_event_lists)) {
          const camelizedData = humps(data);
          const [resources, events] = buildResourcesAndEvents(camelizedData);
          const { meta } = camelizedData;
          return { resources, events, meta };
        }
      }
      return { resources: [], events: [] };
      // eslint-disable-next-line no-empty
    } catch (error) {
      return { resources: [], events: [] };
    }
  };
};

function useGetResourceEvent(notify) {
  const intl = useIntl();
  const visibleResources = useSelector(
    state => state.resourceEvent?.visibleResources,
  );
  const selectedBuilding = useSelector(
    state => state.resourceEvent?.selectedBuilding,
  );
  const events = useSelector(state => state.resourceEvent?.events);
  const isLoading = useSelector(state => state.resourceEvent?.isLoading);
  const isLoadingMore = useSelector(
    state => state.resourceEvent?.isLoadingMore,
  );
  const calendarEvent = useSelector(
    state => state.resourceEvent?.calendarEvent,
  );
  const newCalendarEvent = useSelector(
    state => state.resourceEvent?.newCalendarEvent,
  );
  const filteringTime = useSelector(
    state => state.resourceEvent?.filteringTime,
  );
  const filteredResources = useSelector(
    state => state.resourceEvent?.filteredResources,
  );
  const filteredEvents = useSelector(
    state => state.resourceEvent?.filteredEvents,
  );
  const tempEvent = useSelector(state => state.resourceEvent?.temporaryEvent);
  const selectedDate = useSelector(state => state.resourceEvent?.selectedDate);
  const availableTimeSlots = useSelector(
    state => state.resourceEvent?.availableTimeSlots,
  );
  const currentPage = useSelector(state => state.resourceEvent?.currentPage);
  const totalPages = useSelector(state => state.resourceEvent?.totalPages);
  const isAvailableInstantMeetingCreate = useSelector(
    state => state.resourceEvent?.isAvailableInstantMeetingCreate,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDate) {
      dispatch(setLoading(true));
      dispatch(
        getResourceAndEvent(notify, selectedBuilding, selectedDate),
      ).then(data => {
        dispatch(setLoading(false));
        dispatch(getVisibleResources(data.resources));
        dispatch(getEvents(data.events, selectedDate));
        if (!isEmpty(data.meta)) {
          dispatch(setCurrentPage(data.meta.currentPage));
          dispatch(setTotalPages(data.meta.totalPages));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding, selectedDate]);

  const setDate = date => {
    // dispatch(setLoading(true));
    dispatch(setSelectedDate(date));
  };

  const forceGetResourceAndEvent = (page = 1) => {
    const currentResources = page > 1 ? visibleResources : [];
    const currentEvents = page > 1 ? events : [];
    dispatch(setLoadingMore(true));
    dispatch(
      getResourceAndEvent(notify, selectedBuilding, selectedDate, page),
    ).then(data => {
      dispatch(setLoadingMore(false));
      dispatch(
        getVisibleResources(
          uniqBy([...currentResources, ...data.resources], 'resourceId'),
        ),
      );
      dispatch(
        getEvents(
          uniqBy([...currentEvents, ...data.events], 'id'),
          selectedDate,
        ),
      );
      if (!isEmpty(data.meta)) {
        dispatch(setCurrentPage(data.meta.currentPage));
        dispatch(setTotalPages(data.meta.totalPages));
      }
    });
  };

  const updateResourceAndEvent = async lastPage => {
    const getResourceAndEventDispatchs = [];
    for (let page = 1; page < lastPage + 1; page += 1) {
      getResourceAndEventDispatchs.push(
        dispatch(
          getResourceAndEvent(notify, selectedBuilding, selectedDate, page),
        ),
      );
    }
    const resourceAndEvents = await Promise.all(getResourceAndEventDispatchs);
    dispatch(
      getVisibleResources(
        resourceAndEvents.flatMap(({ resources }) => resources),
      ),
    );
    dispatch(
      getEvents(
        resourceAndEvents.flatMap(({ events: e }) => e),
        selectedDate,
      ),
    );
  };

  const getCalendarEventDetails = async (resourceUid, handleResult) => {
    dispatch(showLoading());
    try {
      const response = await axios(
        `/company/resources/${resourceUid}/meeting_calendar_events/next/`,
        { timeout: 14000 },
      ).catch(e => e.response);
      const { data, status } = response;

      if (status > 399 && status < 500) {
        dispatch(hideLoading());
        handleResult(
          'error',
          data?.error?.message || messages.getCalEvtErrSubMsg,
        );
      } else if (status === 500) {
        dispatch(hideLoading());
        handleResult('empty', messages.commonErrorMsg);
      } else if (!isEmpty(data) && !isEmpty(data?.meeting_calendar_event)) {
        const { meetingCalendarEvent, resource } = humps(data);
        dispatch(setCalendarEvent(meetingCalendarEvent));
        dispatch(
          setAvailableInstantMeetingCreate(resource.instantMeetingCreate),
        );
        dispatch(hideLoading());
        handleResult('success', null, status);
      }
    } catch (error) {
      if (error) {
        dispatch(hideLoading());
        handleResult('error', messages.commonErrorMsg);
      }
    }
  };

  const postCheckIn = async (meeting, handleResult, resourceUid) => {
    dispatch(showLoading());
    try {
      const response = await axios(
        `/company/resources/${resourceUid}/meetings/checkin`,
        {
          data: JSON.stringify({ meeting }),
          method: 'POST',
          timeout: 14000,
        },
      ).catch(e => e.response);
      const { data, status } = response;

      if (status > 399 && status < 500) {
        broadcastCheckIn(false);
        handleResult(
          'error',
          data?.error?.message || messages.postChkInErrSubMsg,
        );
        dispatch(hideLoading());
      } else if (status === 500) {
        broadcastCheckIn(false);
        handleResult('error', messages.commonErrorMsg);
        dispatch(hideLoading());
      } else if (!isEmpty(data) && !isEmpty(data?.meeting)) {
        const camelizedData = humps(data?.meeting);
        dispatch(setMeeting(camelizedData));
        const updatedEvents = buildUpdatedEvents(
          events,
          camelizedData,
          resourceUid,
        );

        dispatch(getEvents(updatedEvents));
        broadcastCheckIn(true);
        handleResult('success', messages.checkInSuccessMsg);
        dispatch(hideLoading());
      }
    } catch (error) {
      if (error) {
        dispatch(hideLoading());
        broadcastCheckIn(false);
        handleResult('error', messages.commonErrorMsg);
      }
    }
  };

  const putCheckOut = async (meeting, handleResult, resourceUid) => {
    dispatch(showLoading());
    try {
      const response = await axios(
        `/company/resources/${resourceUid}/meetings/${meeting.uid}/checkout`,
        {
          data: { checked_out_with: meeting.checked_out_with },
          method: 'PUT',
          timeout: 14000,
        },
      ).catch(e => e.response);
      const { data, status } = response;

      if (status > 399 && status < 500) {
        broadcastCheckOut(false);
        handleResult(
          'error',
          data?.error?.message || messages.putChkOutErrSubMsg,
        );
        dispatch(hideLoading());
      } else if (status === 500) {
        broadcastCheckOut(false);
        handleResult('error', messages.commonErrorMsg);
        dispatch(hideLoading());
      } else if (!isEmpty(data) && !isEmpty(data?.meeting)) {
        const camelizedData = humps(data.meeting);
        dispatch(setMeeting(camelizedData));
        const updatedEvents = buildUpdatedEvents(
          events,
          camelizedData,
          resourceUid,
        );
        dispatch(getEvents(updatedEvents));
        broadcastCheckOut(true);
        handleResult('success', messages.checkOutSuccessMsg);

        // added setLoading so that the eventNode style height in EventContent file is reset after checkout.
        if (!isMobileDevice()) {
          dispatch(setLoading(true));
          dispatch(setLoading(false));
        }
        dispatch(hideLoading());
      }
    } catch (error) {
      if (error) {
        dispatch(hideLoading());
        broadcastCheckOut(false);
        handleResult('error', messages.commonErrorMsg);
      }
    }
  };

  const postInstantMeeting = async (payload, handleResult, resourceUid) => {
    dispatch(showLoading());
    try {
      const response = await axios(
        `/company/resources/${resourceUid}/instant_meeting_calendar_events`,
        {
          method: 'POST',
          data: JSON.stringify({ meeting_calendar_event: payload }),
          timeout: 14000,
        },
      ).catch(e => e.response);
      const { data, status } = response;

      if (status > 399 && status < 500) {
        broadcastInstaMeeting(false);
        handleResult('error', data?.error?.message || messages.postEventErrMsg);
        dispatch(hideLoading());
      } else if (status === 500) {
        broadcastInstaMeeting(false);
        handleResult('error', messages.commonErrorMsg);
        dispatch(hideLoading());
      } else if (!isEmpty(data) && !isEmpty(data?.meeting_calendar_event)) {
        const camelizedData = humps(data?.meeting_calendar_event);
        dispatch(setMeeting(camelizedData));
        dispatch(
          getEvents([
            ...events,
            {
              ...camelizedData?.calendarEvent,
              start: new Date(camelizedData.calendarEvent?.start),
              end: new Date(camelizedData.calendarEvent?.end),
              resourceId: resourceUid,
            },
          ]),
        );
        broadcastInstaMeeting(true);
        handleResult('success');
        dispatch(hideLoading());
      }
    } catch (error) {
      if (error) {
        dispatch(hideLoading());
        broadcastInstaMeeting(false);
        handleResult('error', messages.commonErrorMsg);
      }
    }
  };

  const filterByTime = filterTime => {
    dispatch(setFilteringTime(filterTime));
    if (filterTime) {
      const currentTime = new Date();
      const endTime = moment().add(filterTime, dateUnitTypes.MINUTES).toDate();
      const allEventsWithOccupy = [];

      const filterResources = resource => {
        let overlappingEventCount = 0;
        const eventsFiltered = events.filter(event => {
          if (resource.resourceId === event.resourceId) {
            if (
              (currentTime >= event.start && currentTime <= event.end) ||
              (endTime >= event.start && endTime <= event.end) ||
              (event.checkedInAt && !event.checkedOutAt)
            ) {
              overlappingEventCount += 1;
              return false;
            }
            return true;
          }
          return false;
        });

        if (overlappingEventCount === 0) {
          allEventsWithOccupy.push(...eventsFiltered, {
            name: intl.formatMessage(messages.occupy),
            start: currentTime,
            end: endTime,
            resourceId: resource.resourceId,
            id: uniqueId(),
            occupyNew: true,
          });
          return true;
        }
        return false;
      };

      const resourcesFiltered = visibleResources.filter(filterResources);
      dispatch(setFilteredResources(resourcesFiltered));
      dispatch(setFilteredEvents(allEventsWithOccupy));
    }
  };

  useEffect(() => {
    if (filteringTime) {
      filterByTime(filteringTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, filteringTime]);

  const postCalendarEvent = async (payload, resourceUid, setTempEvent) => {
    dispatch(showLoading());
    try {
      const { data } = await axios(
        `/company/resources/${resourceUid}/calendar_events`,
        {
          data: JSON.stringify({ event: payload }),
          method: 'POST',
        },
      );

      if (!data || (data && data?.error)) {
        notify.setError(
          messages.postEventErrMsg,
          data?.error?.message || messages.postEventErrSubMsg,
        );
        setTempEvent({});
        dispatch(hideLoading());
      } else if (!isEmpty(data) && !isEmpty(data?.calendar_event)) {
        const event = humps(data?.calendar_event);
        dispatch(setNewCalendarEvent(data?.calendar_event));
        dispatch(
          getEvents([
            ...events,
            {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
              resourceId: resourceUid,
            },
          ]),
        );
        notify.setNotify(messages.postEventSuccessMsg);
        setTempEvent({});
        dispatch(hideLoading());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const deleteCalendarEvent = async (
    resourceUid,
    eventId,
    organizerCalendar,
  ) => {
    dispatch(showLoading());
    try {
      const params = {};
      if (selectedDate) {
        params.date = moment(selectedDate).format('YYYY-MM-DD');
      }

      if (organizerCalendar != null) {
        params.calendar_id = organizerCalendar;
      }

      const response = await axios(
        `/company/resources/${resourceUid}/calendar_events/${eventId}${
          Object.getOwnPropertyNames(params).length
            ? `?${new URLSearchParams(params)}`
            : ''
        }`,
        {
          method: 'DELETE',
        },
      ).catch(e => e.response);
      const { data, status } = response;

      if (status > 399 && status < 500) {
        dispatch(hideLoading());
        notify.setError(
          messages.delEventErrMsg,
          data?.error?.message || messages.delEventErrSubMsg,
        );
      } else if (status === 500) {
        dispatch(hideLoading());
        notify.setError(messages.commonErrorMsg);
      } else if (status >= 200 && status < 298) {
        const latestEvents = events.filter(
          event => eventId !== event.id && eventId !== event.microsoftCalUid,
        );
        dispatch(getEvents(latestEvents));
        notify.setNotify(messages.deleteEventSuccessMsg);
        dispatch(hideLoading());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const setTempEvent = (eventSlot, timeFormat) => {
    if (!isEmpty(eventSlot)) {
      const name = timeRangeFormat(eventSlot, timeFormat, 'en');
      dispatch(
        setTemporaryEvent({
          start: eventSlot?.start,
          end: eventSlot?.end,
          resourceId: eventSlot?.resourceId,
          name,
          id: uniqueId(),
          temporary: true,
        }),
      );
    } else {
      dispatch(setTemporaryEvent({}));
    }
  };

  const downloadMeetingRoomCsv = async query => {
    dispatch(showLoading());
    const response = await axios(`/company/csv/export_meetings?${query}`).catch(
      e => e.response,
    );
    const { data } = response;

    if (!data || (data && data?.error)) {
      notify.setError(messages.dlMtgRoomsErrMsg, data?.error?.message || '');
      dispatch(hideLoading());
    } else if (!isEmpty(data)) {
      const fileName = `RECEPTIONIST会議室利用履歴-${moment().format(
        'YYYYMMDD',
      )}.csv`;
      downloadFile(response, fileName);
      notify.setNotify(messages.dlMtgRoomsSuccessMsg);
      dispatch(hideLoading());
    }
  };

  const searchTimeSlots = async (valuesObj, handleResult) => {
    dispatch(showLoading());
    dispatch(setLoading(true));
    try {
      let query = '';
      query =
        `?building=${valuesObj.building}` +
        `&duration=${valuesObj.duration}` +
        `&time_start=${valuesObj.timeStart}` +
        `&time_end=${valuesObj.timeEnd}` +
        `&date_start=${valuesObj.dateStart}` +
        `&date_end=${valuesObj.dateEnd}` +
        `&holidays=${valuesObj.holidays}`;

      const response = await axios(
        `company/resource_calendar_event_lists/search${query}`,
      ).catch(e => e.response);
      const { data /* , status */ } = response;

      if (!data || (data && data?.error)) {
        notify.setError(
          messages.commonErrorMsg,
          data?.error?.message || messages.commonErrorMsg,
        );
        dispatch(setAvailableTimeSlots([]));
        dispatch(hideLoading());
        dispatch(setLoading(false));
      } else if (!isEmpty(data)) {
        if (!isEmpty(data?.available_dates)) {
          dispatch(setAvailableTimeSlots(data.available_dates));
          handleResult('success');
        } else {
          dispatch(setAvailableTimeSlots([]));
          handleResult('empty');
        }
        dispatch(hideLoading());
        dispatch(setLoading(false));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    events,
    visibleResources,
    isLoading,
    isLoadingMore,
    getCalendarEventDetails,
    calendarEvent,
    postCheckIn,
    putCheckOut,
    postInstantMeeting,
    postCalendarEvent,
    newCalendarEvent,
    filterByTime,
    filteredResources,
    filteringTime,
    filteredEvents,
    downloadMeetingRoomCsv,
    setTempEvent,
    tempEvent,
    deleteCalendarEvent,
    selectedDate,
    setDate,
    searchTimeSlots,
    availableTimeSlots,
    forceGetResourceAndEvent,
    updateResourceAndEvent,
    currentPage,
    totalPages,
    isAvailableInstantMeetingCreate,
  };
}

export { useGetResourceEvent };
