import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BigCalendar from 'components/BigCalendar';
import { isMobileDevice } from 'utils/mobileUtils';
import ResourcesList from './components/ResourcesList';
import { Wrapper, MobileWrapper } from './components/styled';
import { useGetResourceEvent } from './hooks/useGetResourceEvent';
import { useBuildings } from './hooks/useBuildings';
import { useGetSettingResourceApp } from './hooks/useGetSettingResourceApp';
import { useGetResources } from './hooks/useGetResources';

const Home = ({ intl, useNotify, fullscreenState }) => {
  const notify = useNotify(intl);

  const {
    visibleResources,
    events,
    isLoading,
    isLoadingMore,
    getCalendarEventDetails,
    calendarEvent,
    postCheckIn,
    putCheckOut,
    postInstantMeeting,
    postCalendarEvent,
    newCalendarEvent,
    filterByTime,
    filteredResources,
    filteringTime,
    filteredEvents,
    downloadMeetingRoomCsv,
    setTempEvent,
    tempEvent,
    deleteCalendarEvent,
    selectedDate,
    setDate,
    searchTimeSlots,
    availableTimeSlots,
    forceGetResourceAndEvent,
    updateResourceAndEvent,
    currentPage,
    totalPages,
    isAvailableInstantMeetingCreate,
  } = useGetResourceEvent(notify);

  const {
    fetchBuildings,
    buildings,
    setBuilding,
    selectedBuilding,
  } = useBuildings();

  const {
    fetchSettingResourceApp,
    settingResourceApp,
  } = useGetSettingResourceApp();

  const { fetchResources, resources } = useGetResources();

  if (!isMobileDevice()) {
    return (
      <Wrapper fullscreenState={fullscreenState}>
        <BigCalendar
          resourceMap={visibleResources}
          events={events}
          postCalendarEvent={postCalendarEvent}
          newCalendarEvent={newCalendarEvent}
          filterByTime={filterByTime}
          filteredResources={filteredResources}
          filteringTime={filteringTime}
          filteredEvents={filteredEvents}
          downloadMeetingRoomCsv={downloadMeetingRoomCsv}
          setTempEvent={setTempEvent}
          tempEvent={tempEvent}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          deleteCalendarEvent={deleteCalendarEvent}
          setDate={setDate}
          selectedDate={selectedDate}
          fetchBuildings={fetchBuildings}
          buildings={buildings}
          setBuilding={setBuilding}
          selectedBuilding={selectedBuilding}
          fetchResourceAndEvent={forceGetResourceAndEvent}
          updateResourceAndEvent={updateResourceAndEvent}
          fullscreenState={fullscreenState}
          postCheckIn={postCheckIn}
          putCheckOut={putCheckOut}
          fetchSettingResourceApp={fetchSettingResourceApp}
          settingResourceApp={settingResourceApp}
          notify={notify}
          currentPage={currentPage}
          totalPages={totalPages}
          allResources={resources}
          fetchAllResources={fetchResources}
        />
      </Wrapper>
    );
  }

  return (
    <MobileWrapper>
      <ResourcesList
        resources={visibleResources}
        events={events}
        getCalendarEventDetails={getCalendarEventDetails}
        calendarEvent={calendarEvent}
        postCheckIn={postCheckIn}
        putCheckOut={putCheckOut}
        postInstantMeeting={postInstantMeeting}
        fetchBuildings={fetchBuildings}
        buildings={buildings}
        setBuilding={setBuilding}
        selectedBuilding={selectedBuilding}
        setDate={setDate}
        isLoading={isLoading}
        searchTimeSlots={searchTimeSlots}
        availableTimeSlots={availableTimeSlots}
        fetchResourceAndEvent={forceGetResourceAndEvent}
        updateResourceAndEvent={updateResourceAndEvent}
        currentPage={currentPage}
        totalPages={totalPages}
        isLoadingMore={isLoadingMore}
        isAvailableInstantMeetingCreate={isAvailableInstantMeetingCreate}
      />
    </MobileWrapper>
  );
};

Home.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  useNotify: PropTypes.func.isRequired,
  fullscreenState: PropTypes.bool,
};

Home.defaultProps = {
  fullscreenState: false,
};

export default injectIntl(Home);
