import React from 'react';
import { Row, Col } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import moment from 'moment';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEventInitialValues } from 'hooks/useEventInitialValues';
import { useValidation, composeValidators } from 'hooks/useValidation';
import { useGetSettingResourceApp } from 'containers/Home/hooks/useGetSettingResourceApp';
import messages from '../messages';
import {
  CustomEventModal,
  CustomForm,
  ModalTitle,
  DragBar,
  TitleLabel,
  InputField,
  ConfirmationDialogButton,
  DescriptionText,
} from './styled';
import { TextAreaInput } from './TextAreaInput';

function EventCreateDialog({
  onConfirm,
  timeSlot,
  timeFormat,
  isHomeBooking,
  ...otherProps
}) {
  const [currentUser] = useCurrentUser();
  const formattedStartTime =
    (!isHomeBooking
      ? moment(timeSlot.start).format('MMMM Do ')
      : moment(timeSlot.start).format('M/D(ddd) ')) +
    moment(timeSlot.start).locale('en').format(timeFormat);
  const formattedEndTime = moment(timeSlot.end).locale('en').format(timeFormat);
  const multitenantRelated =
    currentUser.company.has_subcompany || currentUser.company.is_subcompany;
  const { settingResourceApp } = useGetSettingResourceApp();
  const { titleRequired, inputLength255 } = useValidation();
  const { initialValues, descriptions } = useEventInitialValues(
    currentUser.company.id,
  );

  return (
    <CustomEventModal {...otherProps} height="auto">
      <Form
        onSubmit={onConfirm}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <CustomForm onSubmit={handleSubmit}>
            <DragBar />
            <Row width="100%">
              <Col
                xs
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <ModalTitle isHomeBooking={isHomeBooking}>
                  {`${formattedStartTime} - ${formattedEndTime}`}
                  {isHomeBooking && (
                    <>
                      <br />
                      {timeSlot.resource.name}
                    </>
                  )}
                </ModalTitle>
              </Col>
            </Row>

            {(!currentUser.company.is_subcompany ||
              (currentUser.company.is_subcompany &&
                settingResourceApp.showCalendarEventTitlesMultitenant)) && (
              <Row width="100%" mt="13px">
                <Col xs pl="16px" pr="16px">
                  <Row>
                    <Col>
                      <TitleLabel>
                        <FormattedMessage {...messages.titleLabel} />
                        {descriptions.title != null ? (
                          <DescriptionText>
                            {descriptions.title}
                          </DescriptionText>
                        ) : null}
                      </TitleLabel>
                    </Col>
                  </Row>
                  <Row mt="9px">
                    <Col xs>
                      <Field
                        id="title"
                        name="title"
                        component={InputField}
                        placeholder=""
                        validate={composeValidators(
                          titleRequired,
                          inputLength255,
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {multitenantRelated && (
              <Row width="100%">
                <Col xs pl="16px" pr="16px">
                  <Row>
                    <Col xs>
                      <TitleLabel>
                        <FormattedMessage {...messages.noteLabel} />
                        {descriptions.notes != null ? (
                          <DescriptionText>
                            {descriptions.notes}
                          </DescriptionText>
                        ) : null}
                      </TitleLabel>
                    </Col>
                  </Row>
                  <Row mt="9px">
                    <Col xs>
                      <Field
                        id="notes"
                        name="notes"
                        component={TextAreaInput}
                        placeholder=""
                        validate={inputLength255}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Row mt="25px" width="100%" justifyContent="center">
              <Col width="77%">
                <ConfirmationDialogButton
                  className="confirm_button"
                  type="submit"
                >
                  <FormattedMessage {...messages.reserveButton} />
                </ConfirmationDialogButton>
              </Col>
            </Row>
          </CustomForm>
        )}
      />
    </CustomEventModal>
  );
}

EventCreateDialog.propTypes = {
  onConfirm: PropTypes.oneOfType([PropTypes.func]).isRequired,
  timeSlot: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeFormat: PropTypes.string.isRequired,
  isHomeBooking: PropTypes.bool,
};

EventCreateDialog.defaultProps = {
  isHomeBooking: false,
};

export default EventCreateDialog;
